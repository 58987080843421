<template>
  <div class="auth-providers" :class="platform">
    <div class="email-registration">
      <StButton
        class="email-button"
        :size="isMobile ? 'l' : 'xl'"
        :label="t('authorization.registration')"
        replace
        :to="{ query: { modal: 'register' } }"
      />
      <img
        class="email-present"
        :src="presentUrl"
        alt=""
        :width="isMobile ? 36 : 44"
        :height="isMobile ? 36 : 44"
      />
    </div>
    <div v-if="!isTelegramMiniApp" class="buttons-wrapper">
      <span class="buttons-text">{{ t('authorization.continueWith') }}</span>
      <div class="buttons">
        <StButton
          v-if="isGoogleEnabled"
          :type="isMobile ? 'gray' : 'ghost'"
          class="provider-button"
          size="m"
          icon="c-google"
          data-t="google-button"
          :loading="currentLoadingProvider === 'google'"
          :label="isMobile ? t('registration.googleButtonShort') : ''"
          :is-left-icon="isMobile"
          @click="auth('google')"
        />
        <StButton
          class="provider-button"
          size="m"
          :type="isMobile ? 'gray' : 'ghost'"
          icon="c-telegram"
          data-t="telegram-sign-in"
          :loading="currentLoadingProvider === 'telegram'"
          :is-left-icon="isMobile"
          :label="isMobile ? t('registration.telegramButton') : ''"
          @click="auth('telegram')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTelegram } from '@st/telegram/composables/useTelegram'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useAuthProviders } from '../../composables/useAuthProviders'
import PresentDesk from './assets/present-desk.png'
import PresentMob from './assets/present-mob.png'

const { platform, isMobile } = usePlatform()

const { t } = useI18n()

const { settings } = storeToRefs(useSettingsStore())
const isGoogleEnabled = computed(
  () => !!settings.value?.oidcGoogleRegistrationEnabled,
)

const { currentLoadingProvider, auth } = useAuthProviders()

const { isTelegramMiniApp } = useTelegram()

const presentUrl = computed(() => (isMobile.value ? PresentMob : PresentDesk))
</script>

<style scoped>
.buttons-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
  justify-content: flex-end;

  min-width: 176px;
  height: 48px;
}

.buttons {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.buttons-text {
  position: absolute;
  top: -6px;
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.email-registration {
  position: relative;
}

.email-present {
  position: absolute;
  top: 2px;
  right: -24px;
}

.auth-providers {
  display: flex;
  gap: var(--spacing-450);
  align-items: center;

  padding: var(--spacing-300);

  backdrop-filter: blur(1px);

  &.mobile {
    flex-direction: column;
    gap: var(--spacing-125);
    padding: 0 var(--spacing-200);
    padding-bottom: var(--spacing-350);

    .buttons-wrapper {
      gap: var(--spacing-125);
      align-items: center;
      width: 100%;
      height: auto;
    }

    .buttons-text {
      position: static;
      top: unset;
      font: var(--mobile-caption-1-regular);
    }

    .buttons {
      gap: var(--spacing-075);
      justify-content: center;
      width: 100%;
    }

    .provider-button {
      min-width: 124px;
    }

    .email-present {
      top: 2px;
      right: -18px;
    }

    .email-button {
      min-width: 160px;
    }
  }
}
</style>
