import type { ComputedRef } from 'vue'
import bnb from './assets/bnb.svg'
import btc from './assets/btc.svg'
import eth from './assets/eth.svg'
import trx from './assets/trx.svg'
import usdt from './assets/usdt.svg'
import usdc from './assets/usdc.svg'
import ltc from './assets/ltc.svg'
import matic from './assets/matic.svg'
import ton from './assets/ton.svg'

interface useCurrenciesBadgesReturn {
  currencies: ComputedRef<{ id: string; icon: string }[]>
}
export function useCurrenciesBadges(): useCurrenciesBadgesReturn {
  const currencies = computed(() => [
    {
      id: 'BTC',
      icon: btc,
    },
    {
      id: 'USDT',
      icon: usdt,
    },
    {
      id: 'ETH',
      icon: eth,
    },
    {
      id: 'BNB',
      icon: bnb,
    },
    {
      id: 'USDC',
      icon: usdc,
    },
    {
      id: 'TRX',
      icon: trx,
    },
    {
      id: 'LTC',
      icon: ltc,
    },
    {
      id: 'TON',
      icon: ton,
    },
    {
      id: 'MATIC',
      icon: matic,
    },
  ])

  return { currencies }
}
