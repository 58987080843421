import type { TournamentType, TournamentStatus } from '../../types'

export function useTournamentsSlider() {
  const { t, locale } = useI18n()

  const selectedTab = ref<TournamentType>('all')
  const tabs = computed(() => [
    {
      id: 'all',
      label: t('tournament.all'),
      icon: 'grid-solid' as const,
    },
    {
      id: 'casino',
      label: t('tournament.casino'),
      icon: 'casino' as const,
    },
    {
      id: 'bookmaker',
      label: t('tournament.sport'),
      icon: 'all-sports' as const,
    },
    {
      id: 'cyber',
      label: t('tournament.cybersport'),
      icon: 'cybersport' as const,
    },
  ])
  const allTypes: TournamentType[] = ['all', 'bookmaker', 'cyber', 'casino']

  const { data: ratRaces } = useStFetch('/rat-race/find', {
    method: 'post',
    body: computed(() => ({
      params: {
        language: locale.value as any,
        type: selectedTab.value === 'all' ? allTypes : [selectedTab.value],
        status: ['open'] as TournamentStatus[],
      },
      pagination: {
        page: 0,
        perPage: 20,
        orderBy: [{ fieldName: 'createdAt', sortOrder: 'DESC' } as const],
      },
    })),
  })

  const tournaments = computed(() => ratRaces.value?.data ?? [])

  const isDefaultContainer = ref(true)
  watchEffect(() => {
    if (selectedTab.value === 'all' && tournaments.value.length > 3)
      isDefaultContainer.value = false
  })

  return {
    selectedTab,
    tabs,
    tournaments,
    isDefaultContainer,
  }
}
