import { parseLanguageMap } from '@st/utils'
import type { ExtendedMenuItem } from '@st/nuxt-markets-store/types'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { CardsConfig, TopListItem } from './types'
import sport from './img/sport.png'
import casino from './img/casino.png'
import mCasino from './img/m-casino.png'
import mSport from './img/m-sport.png'
import mCyber from './img/m-cyber.png'
import cyber from './img/cyber.png'
import zipper from './img/zipper.svg'
import casinoLogo1 from './img/casino-logo1.svg'
import casinoLogo2 from './img/casino-logo2.svg'
import casinoLogo3 from './img/casino-logo3.svg'
import casinoLogo4 from './img/casino-logo4.svg'
import sportLogo1 from './img/sport-logo1.svg'
import sportLogo2 from './img/sport-logo2.svg'
import sportLogo3 from './img/sport-logo3.svg'
import sportLogo4 from './img/sport-logo4.svg'
import sportLogo5 from './img/sport-logo5.svg'
import cyberLogo1 from './img/cyber-logo1.svg'
import cyberLogo2 from './img/cyber-logo2.svg'
import cyberLogo3 from './img/cyber-logo3.svg'
import cyberLogo4 from './img/cyber-logo4.svg'
import cyberLogo5 from './img/cyber-logo5.svg'

const HOME_ITEMS_COUNT = 3

export function useHomeNavigation() {
  const i18n = useI18n()

  const casinoOverviews: Record<string, string> = {
    crash: i18n.t('casinoGames.crashGames'),
    crush: i18n.t('casinoGames.crashGames'),
    slots: i18n.t('casinoGames.slotsGames'),
    gameShows: i18n.t('casinoGames.gameShow'),
    casinoLive: i18n.t('casinoGames.live'),
  }

  const { mainCategories } = storeToRefs(useCasinoStore())
  const casinoItemsList = computed<TopListItem[]>(() =>
    mainCategories.value.slice(0, HOME_ITEMS_COUNT).map((category) => ({
      title: category.name ?? '',
      subtitle: casinoOverviews[category.code] ?? '',
      iconRaw: category.icon ?? undefined,
      link: `/casino/categories/${category.code}`,
    })),
  )

  const { menu: sportMenu } = useSportMenu()
  const topSportMenuItems = computed(
    () =>
      sportMenu.value
        .filter((item) => item.type === 'sport')
        .slice(0, HOME_ITEMS_COUNT) ?? [],
  )
  const topSportItemsList = computed<TopListItem[]>(() =>
    topSportMenuItems.value.map((sportItem) => {
      const categoriesForSubTitle = sportItem.children
        ?.slice(0, HOME_ITEMS_COUNT)
        .map((category) =>
          parseLanguageMap(
            category.children?.[0]?.name ?? '',
            i18n.locale.value,
          ),
        )
        .join(', ')
      return {
        title: sportItem.name,
        subtitle: categoriesForSubTitle ?? '',
        icon: (sportItem.icon ?? 'all-sports') as IconName,
        link: sportItem.link,
        count: sportItem.liveSportEventsNumber ?? 0,
      }
    }),
  )

  const { menu: cybersportMenu } = useCybersportMenu()
  const topCybersportMenuItems = computed(
    () => cybersportMenu.value[0]?.children?.slice(0, HOME_ITEMS_COUNT) ?? [],
  )
  const topCybersportItemsList = computed<TopListItem[]>(() =>
    topCybersportMenuItems.value.map((categoryItem) => {
      const { name, icon, liveSportEventsNumber, link } =
        categoryItem as ExtendedMenuItem

      const categoriesForSubTitle = categoryItem.children
        ?.slice(0, HOME_ITEMS_COUNT)
        .map((tournament) =>
          parseLanguageMap(tournament.name ?? '', i18n.locale.value),
        )
        .join(', ')

      return {
        title: name,
        subtitle: categoriesForSubTitle ?? '',
        icon: (icon ?? 'all-sports') as IconName,
        link,
        count: liveSportEventsNumber ?? 0,
      }
    }),
  )

  const cardsConfig = computed<CardsConfig[]>(() => [
    {
      title: i18n.t('homeNavigation.casinoTitle'),
      tagName: i18n.t('homeNavigation.casinoTagName'),
      img: casino,
      mobileImg: mCasino,
      type: 'casino',
      tagIcon: zipper,
      topTitle: i18n.t('homeNavigation.casinoTopTitle'),
      topList: casinoItemsList.value,
      logos: [casinoLogo1, casinoLogo2, casinoLogo3, casinoLogo4],
      link: '/casino',
    },
    {
      title: i18n.t('homeNavigation.sportTitle'),
      tagName: i18n.t('homeNavigation.sportTagName'),
      img: sport,
      mobileImg: mSport,
      type: 'sport',
      topTitle: i18n.t('homeNavigation.sportTopTitle'),
      topList: topSportItemsList.value,
      logos: [sportLogo3, sportLogo2, sportLogo1, sportLogo5, sportLogo4],
      link: '/sport',
    },
    {
      title: i18n.t('homeNavigation.cyberTitle'),
      tagName: i18n.t('homeNavigation.cyberTagName'),
      img: cyber,
      mobileImg: mCyber,
      type: 'cyber',
      topTitle: i18n.t('homeNavigation.cyberTopTitle'),
      topList: topCybersportItemsList.value,
      logos: [cyberLogo1, cyberLogo2, cyberLogo3, cyberLogo4, cyberLogo5],
      link: '/cybersport',
    },
  ])

  return { cardsConfig }
}
